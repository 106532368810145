.react-datepicker-popper {
  z-index: 1090;
}

.react-datepicker {
  @apply !rounded-xl overflow-hidden text-slate-200 font-sans bg-slate-900 border-slate-700 shadow-[0px_0px_30px_5px_rgba(0,0,0,0.30)];
}

.react-datepicker__month-container {
  @apply rounded-t-xl overflow-hidden;
}

.react-datepicker__header {
  @apply bg-slate-900 border-slate-200/5 h-[64px];
}

.react-datepicker__header--time {
  @apply bg-slate-900 !rounded-t-xl overflow-hidden h-[64px];
}

.react-datepicker__time-container {
  @apply border-slate-200/5;
}

.react-datepicker__time {
  @apply !bg-slate-900 !rounded-b-xl overflow-hidden;
}

.react-datepicker__navigation {
  @apply top-[10px];
}

.react-datepicker__time-list {
  overflow-y: overlay !important;
  height: 167px !important;
}
.react-datepicker__time-box {
  width: unset !important;
  @apply pt-2;
}

.react-datepicker__time-list::-webkit-scrollbar {
  background-color: transparent;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0);
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.react-datepicker__time-list::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.react-datepicker__time-list::-webkit-scrollbar-corner {
  background-color: transparent;
}

.react-datepicker__time-list-item {
  @apply text-slate-400 grid justify-center items-center mx-2 !py-0 font-semibold;
}
.react-datepicker__time-list-item:hover {
  @apply text-slate-200 !bg-transparent;
}

.react-datepicker__time-list-item--selected {
  @apply !text-blue !bg-transparent;
}

.react-datepicker-time__header {
  @apply text-slate-200 capitalize font-medium;
}

.react-datepicker__day--disabled {
  @apply !text-slate-500;
}

.react-datepicker__day--selected {
  @apply bg-blue !text-white !rounded-lg;
}

.react-datepicker__day {
  @apply text-slate-300 font-medium;
}

.react-datepicker__day:hover {
  @apply bg-slate-700 text-white;
}

.react-datepicker__current-month {
  @apply text-slate-200 font-medium;
}

.react-datepicker__day-name {
  @apply text-slate-500;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  @apply !border-b-slate-700;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  @apply !border-b-slate-900;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
  @apply !border-t-slate-700;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
  @apply !border-t-slate-900;
}
