:root,
::before,
::after {
    --font-sans: 'InterVariable', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color-scheme: dark;
    --background-color: #0f172a;
    --color: #ffffff;
}

@media (prefers-color-scheme: dark) {
    :root {
        color-scheme: dark;
        --background-color: #0f172a;
        --color: #ffffff;
    }
}
